import React from "react";

const Socialmediaicons = () => {
  return (
    <>
      <div className="icons-container">
        <div class="social-icons">
          <div class="icon icon-1">
            <a href="https://wa.link/bhr1qu">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div className="icon icon-2">
            <a href="https://x.com/MMN4Global?s=09">
              <i className="fa fa-x"></i>
            </a>
          </div>
          <div class="icon icon-3">
            <a href="https://www.instagram.com/mmn4.global?igsh=cDh3dXl3ZGxqcDl1">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Socialmediaicons;
