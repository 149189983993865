import React from 'react';
import { Helmet } from 'react-helmet';

const EmployeeCard = ({ image, name, position, skills }) => {
  return (
<>
<Helmet>
<link rel="stylesheet" href="./css/ourteam.css" />
</Helmet>
<div className="employee-card">
      <div className="emloye-card-overlay">
        <ul>
          {skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      </div>
      <div className="half-top"></div>
      <div className="half-bottom"></div>
      <img src='../assets/icons/logo.svg' alt="logo" className="logo" />
      <div className="emloyee-pic">
        <img src={image} alt={name} className="employee" />
      </div>
      <h3 className="employee-title">{name}</h3>
      <h6 className="employee-subtitle">{position}</h6>
    </div>
</>
  );
};

export default EmployeeCard;