import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutPage';
import Services from './pages/ServicesPage';
import Portfolio from './pages/PortfolioPage';
import Contact from './pages/ContactPage';
import Founders from './pages/FoundersPage';
import Blog from './pages/BlogPage';
import PostDetailsPage from './pages/PostDetailsPage'; // Import the new component
import ServicesPage from './pages/ServicesPage';

const AppRoutes = () => {
 return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/founders" element={<Founders />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/post/:id" component={PostDetailsPage} />


      </Routes>
    </Router>
 );
};

export default AppRoutes;