import React, { useContext, useState, useEffect } from "react";
import { Text } from "../containers/Language";
import { LanguageContext } from "../containers/Language";

const Portfolio = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [portfolioItems, setPortfolioItems] = useState([]);

  useEffect(() => {
    const fetchPortfolioItems = async () => {
      try {
        const response = await fetch('https://api.mmnq8.com/api/portfolios');
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          setPortfolioItems(data.data);
        } else {
          console.error('Failed to fetch portfolio items', data.message);
        }
      } catch (error) {
        console.error('Error fetching portfolio items:', error);
      }
    };

    fetchPortfolioItems();
  }, []);

  return (
    <div className="portofolio">
      <div className="container">
        <div className="portfolio-title">
          <Text tid="Portfolio projects" />
        </div>
        {portfolioItems.map((item) => (
          <div className="port-row" key={item.name_en}>
            <img src={item.images} alt={item[`name_${userLanguage}`]} />
            <span className="arrow-custom" />
            <div className="dec-group special">
              <span>{item[`name_${userLanguage}`]}</span>
              <span className="branding">{item[`description_${userLanguage}`]}</span>
              <a href={item.link} className="port-btn btn-primary">
                <Text tid="View Full Project" />
              </a>
            </div>
          </div>
        ))}
        <a href="/portfolio" className="portofolio-view btn-primary">
          <Text tid="View All" />
        </a>
      </div>
    </div>
  );
};

export default Portfolio;