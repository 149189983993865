import React, { useState } from "react";
import { Text } from "../containers/Language";
import SuccessMessage from "./SuccessMessage";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState(null);
  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch("https://api.mmnq8.com/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();
      setResponse(data);
      console.log(data);
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };
  return (


    <>
    {response && <SuccessMessage response={response} />}
        <footer>
      <div className="container">
        <div className="footer-links">
          <ul>
            <li className="title"><Text tid="Services" /></li>
            <li>
              {" "}
              <a href="/services">Web Development</a>
            </li>
            <li>
              {" "}
              <a href="/services">Content Creation</a>
            </li>
            <li>
              {" "}
              <a href="/services">Digital Marketing</a>
            </li>
            <li>
              {" "}
              <a href="/services">Editing &amp; voiceover</a>
            </li>
            <li>
              {" "}
              <a href="/services">Branding &amp; Design</a>
            </li>
          </ul>
          <ul>
            <li className="title"> <Text tid="About Us" /></li>
            <li>
              {" "}
              <a href="/founders">Founders</a>
            </li>
            <li>
              {" "}
              <a href="/about">Vision</a>
            </li>
            <li>
              {" "}
              <a href="/about">Mission</a>
            </li>
          </ul>
          <ul>
            <li className="title"> <Text tid="Portfolio" /></li>
            <li>
              {" "}
              <a href="" />
            </li>
            <li>
              {" "}
              <a href="" />
            </li>
            <li>
              {" "}
              <a href="" />
            </li>
            <li>
              {" "}
              <a href="" />
            </li>
          </ul>
          <ul>
            <li className="title"> <Text tid="Contact Us" /> </li>
            <li>
              {" "}
              <a href="mailto:info@mmn4.com">info@mmn4.com</a>
            </li>
            <li>
              {" "}
              <a href="tel:00971582730255">00971582730255</a>
            </li>
            <li>
              {" "}
              <a href="">Kuwait</a>
            </li>
          </ul>
          <ul>
            <li className="title"> <Text tid="Blog" /></li>
            <li>
              {" "}
              <a href="" />
            </li>
            <li>
              {" "}
              <a href="" />
            </li>
            <li>
              {" "}
              <a href="" />
            </li>
          </ul>
        </div>
        <div className="subsc-container">
      <div> <Text tid="subscribe message footer" /></div>
      <div className="input-icon">
        <input
          type="email"
          className="subscribe"
          id="emailInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <i className="fa fa-envelope subscribe-icon" />
        <button className="subsc-btn" id="subscribeBtn" onClick={handleSubscribe}>
           <Text tid="subscribe" />
        </button>
      </div>
    </div>

        <div id="successMessage">
          Thank you for joining our community. We are excited to have you on
          board!
        </div>
        <div id="errorMessage">
          Please Make sure you enter your email correctly or This email is
          already subscribed
        </div>
      </div>
      <hr />
      <div className="bottom-footer">
        <span><Text tid="Copyright" /> </span>
        <div className="social-media-list">
        <a href="https://x.com/MMN4Global?s=09">
                   <i className="fa fa-x"/>
                </a>
                <a href="https://www.instagram.com/mmn4.global?igsh=cDh3dXl3ZGxqcDl1">
                <i className="fab fa-instagram"/>
                </a>
                <a href="https://www.facebook.com/profile.php?id=61556072264467">
                <i className="fab fa-facebook"/>
                </a>
        </div>
      </div>
    </footer>

    <div className="mobile-footer">
  <div className="container">
    <img src="./assets/icons/logo.svg" alt="logo" />
    <div className="subsc-mobile">
      <Text tid="subscribe message footer" />
    </div>
    <div className="subs-mobile-container">
      <i className="fa fa-envelope" />
      <input
        type="email"
        className="subscribe-email"
        id="emailInput"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button className="sub-mob-btn" id="subscribeMobileBtn" onClick={handleSubscribe}>
        <Text tid="subscribe" />
      </button>
      <div id="messageElement" />
    </div>
    <div id="successMessageMobile">
      Thank you for joining our community. We are excited to have you on board!
    </div>
    <div id="errorMessageMobile">
      Please Make sure you enter your email correctly or This email is already
      subscribed
    </div>
    <div className="mobileinfofooter">
    <div> <a href="mailto:info@mmn4.com">info@mmn4.com</a></div>
    <div> <a href="tel:00971582730255">00971582730255</a></div>
    </div>
    <div className="social-media-list">
    <a href="https://x.com/MMN4Global?s=09">
                   <i className="fa fa-x"/>
                </a>
                <a href="https://www.instagram.com/mmn4.global?igsh=cDh3dXl3ZGxqcDl1">
                <i className="fab fa-instagram"/>
                </a>
                <a href="https://www.facebook.com/profile.php?id=61556072264467">
                <i className="fab fa-facebook"/>
                </a>
    </div>
    <hr />
    <div className="bootom-footer-m">
       <Text tid="Copyright" />
    </div>
  </div>
</div>

    </>

     

    
  );
};

export default Footer;
