import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ServicesCard from "../components/ServicesCard";
import { Text } from "../containers/Language";
import SuccessMessage from '../components/SuccessMessage'; //  استيراد الكومبونونت الجديد
import ResponseContext from '../pages/ResponseContext';
import Socialmediaicons from "../components/socialmediaicons";
import FixedTopPageBtn from "../components/FixedTopPageBtn";
// import './css/services.css';
const ServicesPage = () => {
  const [response, setResponse] = useState(null);
  const [services, setServices] = useState([]);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const handleClose = (response) => {
    setResponse(response);
  };
  useEffect(() => {
    fetch("https://api.mmnq8.com/api/services")
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setServices(data.data);
        } else {
          console.error("Error fetching data: ", data.message);
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
 }, []);

  const handleNext = () => {
    if (currentServiceIndex < services.length - 1) {
      setCurrentServiceIndex(currentServiceIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentServiceIndex > 0) {
      setCurrentServiceIndex(currentServiceIndex - 1);
    }
  };
  return (
    <ResponseContext.Provider value={{ response, setResponse }}>

      <Helmet>
        <link rel="stylesheet" href="./css/services.css" />
        <title>MMN4 Services</title>
      </Helmet>
      <Socialmediaicons/>
      <FixedTopPageBtn/>
      <Header></Header>

      <main id="main-container">
        {services[currentServiceIndex] && (
          <ServicesCard service={services[currentServiceIndex]}  services={services} successMessage={successMessage} handleClose={handleClose}/>
        )}
        <div className="btn-group-next">
          <button href="" onClick={handleBack} className="Back-btn">
            <Text tid="Back" />
          </button>
          <button href="" onClick={handleNext} className="next-btn">
            <Text tid="Next" />
          </button>
        </div>
        {/* form  */}
        {response && <SuccessMessage response={response} />}
      </main>

      <Footer></Footer>
      </ResponseContext.Provider>

  );
};

export default ServicesPage;
