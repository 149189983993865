import React from 'react';
import Header from '../components/Header';
import Main from '../components/Main';
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';
import CompanyTeam from '../components/CompanyTeam';
import Portfolio from '../components/Portfolio';
import Footer from '../components/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Socialmediaicons from '../components/socialmediaicons';
import FixedTopPageBtn from '../components/FixedTopPageBtn';

// import './css/master.css';
const Home = () => {
    return (
     <>

      <Helmet>
        <title>MMN4 Company</title>
        <link rel="stylesheet" href="./css/master.css"/>
        <meta
      name="description"
      content="MMN Commercial Advertising is your trusted partner for digital branding. We specialize in promoting your brand across various digital platforms, including social media and search engines. Our team of experienced professionals in website design, visual identities, and public content ensures your brand stands out in the digital world. We're committed to supporting your business activities and enhancing your online presence. Let us help you reach your target audience effectively."
    />
      </Helmet>
      <Socialmediaicons/>
      <FixedTopPageBtn/>
      <Header/>
      <Main></Main>
      <AboutUs></AboutUs>
      <Services></Services>
      <CompanyTeam></CompanyTeam>
      <Portfolio></Portfolio>
      <Footer></Footer>
     </>   
    
    );
}

export default Home;
