import React from 'react';

import { LanguageProvider } from './containers/Language';

import Routes from './Router';

function App() {
  return (
    <LanguageProvider>
        <Routes />
    </LanguageProvider>
  );
}

export default App;
