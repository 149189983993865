import React, { useState } from "react";
import { Text } from "../containers/Language";
import { useContext } from "react";
import { LanguageContext } from "../containers/Language";
import SuccessMessage from "./SuccessMessage";
import ResponseContext from '../pages/ResponseContext';
const BookingForm = ({ services, handleClose }) => {
  const { response, setResponse } = useContext(ResponseContext); // Consume the context
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const { userLanguage } = useContext(LanguageContext);
  const languageDirection = userLanguage === "ar" ? "arabic" : "english";
  const [successMessage, setSuccessMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
   };
   const handleSubmit = async (e) => {
    e.preventDefault();

  try {
    const res = await fetch("https://api.mmnq8.com/api/order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        service_id: selectedService,
        desc,
      }),
    });
    const data = await res.json();
    setResponse(data);
    console.log(data);
  } catch (error) {
    console.error("Error subscribing:", error);
  }
};


  return (
    <>
    {/* {response && <SuccessMessage response={response} />} */}
        <div className="form-container">
      
      <form onSubmit={handleSubmit} className="form-order">
        <input
          className="custom"
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          className="custom"
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <select
         className="custom"
          value={selectedService}
          onChange={(e) => setSelectedService(e.target.value)}
          required
        >
          <option value="">Select a Service</option>
          {services.map((service, index) => (
            <option key={index} value={service.id}>
              {service[`name_${userLanguage}`]}
            </option>
          ))}
        </select>
        <textarea
          className="custom"
          placeholder="Description"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          required
        />
        <button className="submit-btn" type="submit">Submit</button>
        <button className="cancel-btn" type="button" onClick={handleClose}>
          Cancel
        </button>
      </form>

      <div className="right-form-sec">
        <h3>Your Order</h3>
        <p>
          {" "}
          Enhance your experience by choosing our professional services. Your
          satisfaction is our priority
        </p>
      </div>
    </div>
    </>

  );
};

export default BookingForm;
