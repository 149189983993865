import React from "react";
import { Text } from "../containers/Language";
const CompanyTeam = () => {
  return (
    <div className="meet-team-sec container">
      <h3 className="meet-team-titl"><Text tid="Company founders" /></h3>
      <div className="team-icon-cont">
        <div className="employ">
          <div className="employ-img-con">
            <img src="./assets/image/Mazen Otabashi.jpg" alt="employ icon" />
          </div>
          <div className="emp-name"><Text tid="Mazen Otabashi" /></div>
          <div className="emp-pos"><Text tid="International Consultant" /></div>
        </div>
        <div className="employ">
          <div className="employ-img-con">
            <img src="./assets/image/team/mahmoud.jpg" alt="employ icon" />
          </div>
          <div className="emp-name"> <Text tid="Mahmoud Shihab" /></div>
          <div className="emp-pos"> <Text tid="CEO" /></div>
        </div>
        <div className="employ">
          <div className="employ-img-con">
            <img src="./assets/image/team/ceo.jpg" alt="employ icon" />
          </div>
          <div className="emp-name"> <Text tid="Mohammed Al-Sharaa" /></div>
          <div className="emp-pos"> <Text tid="Manager" /></div>
        </div>
        <div className="employ">
          <div className="employ-img-con">
            <img src="./assets/image/team/nader.jpg" alt="employ icon" />
          </div>
          <div className="emp-name"><Text tid="Nader Yousef" /></div>
          <div className="emp-pos"> <Text tid="Financial Manager" /></div>
        </div>
      </div>
    </div>
  );
};

export default CompanyTeam;
