import React from "react";

const Main = () => {
  return (
    
      <main>
        <div className="container back-container">
          <img
            className="twitter"
            src="../.././assets/image/twitter logo.png"
            alt="img"
          />
          <img className="tiktok" src="../.././assets/image/tiktok.png" alt="img" />
          <img
            className="instagram"
            src="../.././assets/image/skill-icons_instagram.png"
            alt="img"
          />
          <img
            className="facebook"
            src="../.././assets/image/logos_facebook.png"
            alt="img"
          />
        </div>
      </main>
    
  );
};

export default Main;
