// SuccessMessage.js
import React, { useState, useEffect } from 'react';

const SuccessMessage = ({ response }) => {
  const [isVisible, setIsVisible] = useState(false);
  const message =  response.message ;
  const iconMessage = (response.status || response.success ) ? "fa-check" : "fa-close" ;
  useEffect(() => {
    if (response) {
      setIsVisible(true); //  قم  بتعيين isVisible  إلى true  عندما  يتم  تحديث الريسبونس
    }
  }, [response]);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
<>
<div className='overlayspecial'></div>
<div className="success-message">
      <p>{message}</p>
       <i  className={`fa  ${iconMessage}`}/>
       <button  className='okbtn' onClick={handleClose}>OK</button>
    </div>
</>
  );
};

export default SuccessMessage;