// ServicesCard.js
import React, { useContext, useState } from "react";
import { LanguageContext } from "../containers/Language";
import { Text } from "../containers/Language";
import BookingForm from "./BookingForm";
import ResponseContext from '../pages/ResponseContext'; // Import the context
import SuccessMessage from "./SuccessMessage";

const ServicesCard = ({ service, services , successMessage  }) => {
  const { response } = useContext(ResponseContext); // Consume the context

  // جلب اللغة المختارة من قبل المستخدم
  const { userLanguage } = useContext(LanguageContext);
  const languageDirection = userLanguage === "ar" ? "arabic" : "english";

  const name = service[`name_${userLanguage}`];
  const description = service[`description_${userLanguage}`];
  const imageUrl = service[`imageUrl_${userLanguage}`];
  const [showForm, setShowForm] = useState(false);
  
  const handleBookNowClick = () => {
    setShowForm(true);
  };

  const handleClose = () => {
    setShowForm(false);
  };
  return (
    <div id="services-container" className="container services-page">
            {response && <SuccessMessage response={response} />}

      <div className="service-img-container">
        <img src={imageUrl} alt={name} />
      </div>
      <div className="desc-serv">
        <h2 className={`services-header ${languageDirection}`}>{name}</h2>
        <p className={`service-desc ${languageDirection}`}>{description}</p>
        {showForm && (
          <BookingForm services={services} handleClose={handleClose} />
        )}
        {!showForm && (
          <a href="#" className="serv-book-btn" onClick={handleBookNowClick}>
            <Text tid="Book Now" />
          </a>
        )}
      </div>
      

    </div>
  );
};

export default ServicesCard;
