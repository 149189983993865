import React from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import Aboutus from "../components/AboutUs"
import Socialmediaicons from '../components/socialmediaicons';
import FixedTopPageBtn from '../components/FixedTopPageBtn';
import { Helmet } from 'react-helmet';
const AboutPage = () => {
    return (
        <div>
           <Helmet> 
           <meta
      name="description"
      content="Design and development of websites and electronic applications - leading search engines (SEO) - managing social media accounts - call center - voiceover - increasing interaction and followers on social media platforms - motion graphics - graphic design"
    />
    <title>About MMN4</title>
           </Helmet>
            <Socialmediaicons/>
            <FixedTopPageBtn/>
            <Header></Header>
                <Aboutus />
             <Footer></Footer>
        </div>
    );
}

export default AboutPage;
