import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';
import { LanguageContext } from "../containers/Language";
import SuccessMessage from '../components/SuccessMessage';
import Socialmediaicons from '../components/socialmediaicons';
import FixedTopPageBtn from '../components/FixedTopPageBtn';
const ContactPage = () => {
    const [response, setResponse] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('https://api.mmnq8.com/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            console.log(data);
            setResponse(data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    useEffect(() => {
        //  هنا  يتم  تنفيذ التغيير  بعد  تحميل الصفحة
        const circleBoxIcons = document.querySelectorAll('.circle-box-icon');
        setTimeout(() => {
            circleBoxIcons.forEach(icon => {
                icon.style.top = '-50px';
            });
        },  1000); //  تاخير  بـ  1000  مللي  ثانية (1  ثانية)
    }, []);
    return (
        <div>
            <Helmet>
               <link rel="stylesheet" href="./css/contact.css" />
               <title>MMN4 Contact</title>
               <meta
      name="description"
      content="Get in Touch"
    />
            </Helmet>
            <Socialmediaicons/>
            <FixedTopPageBtn/>
            {response && <SuccessMessage response={response} />}
            <Header></Header>
            <div className="main-contact">
                <div className="contact">
                    <div className="box-ratio">
                        <div className="circle-box"></div>
                        <div className="circle-box-icon"><i className="fas fa-map-marker-alt"></i></div>
                        <p>Kuwait</p>
                    </div>
                    <div className="box-ratio">
                        <div className="circle-box"></div>
                        <div className="circle-box-icon"><i className="fas fa-phone"></i></div>
                        <p>
                            <span>00971582730255</span>
                        </p>
                    </div>
                    <div className="box-ratio">
                        <div className="circle-box"></div>
                        <div className="circle-box-icon"><i className="fas fa-envelope"></i></div>
                        <p>info@mmn4.com</p>
                    </div>
                </div>
                <div className="contact-container">
                    <h2>Get in Touch</h2>
                    <form onSubmit={handleSubmit}>
            <div>
                <i className="fas fa-user"></i>
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                />
            </div>
            <div>
                <i className="fas fa-envelope"></i>
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                />
            </div>
            <div>
                <i className="fas fa-phone"></i>
                <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone"
                    value={formData.phone}
                    onChange={handleChange}
                />
            </div>
            <div>
                <i className="fas fa-comment"></i>
                <textarea
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                ></textarea>
            </div>
            <button type="submit">Send</button>
        </form>
                    <div className="social-contact-area">
                        <a href="https://www.facebook.com/profile.php?id=61556072264467"><i className="fab fa-facebook"></i></a>
                        <a href="https://x.com/MMN4Global?s=09"><i className="fab fa-x"></i></a>
                        <a href="https://www.instagram.com/mmn4.global?igsh=cDh3dXl3ZGxqcDl1"><i className="fab fa-instagram"></i></a>
                        
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default ContactPage;