import React, { useState, useEffect, useContext } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LanguageContext } from "../containers/Language";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Socialmediaicons from '../components/socialmediaicons';
import FixedTopPageBtn from '../components/FixedTopPageBtn';

const BlogPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
const [selectedPost, setSelectedPost] = useState(null);
const handlePostClick = (post) => {
  setSelectedPost(post);
  setModalOpen(true);
};
  const { userLanguage } = useContext(LanguageContext);
  const languageDirection = userLanguage === "ar" ? "arabicpost" : "englishpost";
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("https://api.mmnq8.com/api/posts");
        const data = await response.json();
        if (data.success) {
          setPosts(data.data);
        } else {
          console.error("Failed to fetch posts", data.message);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);
  return (
    <div>
        <Helmet>
        <link rel="stylesheet" href="./css/blog.css" />
        <title>MMN4 Blog</title>
        <meta
      name="description"
      content="Our company specializes in global market research, aiming to facilitate the integration of local products across multiple companies. We define and manage brand strategies, oversee product production, and handle marketing responsibilities."
    />
        </Helmet>
        <Socialmediaicons/>
        <FixedTopPageBtn/>
      <Header></Header>
      <div className="blog">
      <div className="blog-container">
      {/* <div className="blog-cont-ti">
            <div className="blog-cat">Technology</div>
            <div className="blog-title">The Impact of Technology on the Workplace: How Technology is Changing</div>
            <div className="blog-writer">
              <span>Tracey Wilson</span>
              <span>August 20, 2022</span>
            </div>
       </div> */}
        {posts.map((post) => (
          <div className="blogers-posts" key={post.id}>
            <div className="post" onClick={() => handlePostClick(post)}>
              <img src={post.images} alt={post[`title_${userLanguage}`]} />
              <div className="post-cat">{post[`category_${userLanguage}`]}</div>
              <div className={`post-title  ${languageDirection}`}>{post[`title_${userLanguage}`]}</div>
              <div className="post-writer">
              <span>{post['writer by']}</span> {/*  استخدم الأقواس المربعة  للوصول  إلى الاسم المعطى  كسلسلة */}
                <span>{post.created_at}</span>
              </div>
              <div className="post-description" style={{ display: 'none' }}>
                {post[`body_${userLanguage}`]}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    {modalOpen && (
  <div className="modal">
    <div  className={`modal-content  ${languageDirection}`}>
      <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
      {selectedPost && (
        <>
          <img src={selectedPost.images} alt={selectedPost[`title_${userLanguage}`]} />
          <div className="post-cat">{selectedPost[`category_${userLanguage}`]}</div>
          <div className={`post-title  ${languageDirection}`}>{selectedPost[`title_${userLanguage}`]}</div>
          <div className="post-writer">
            <span>{selectedPost['writer by']}</span>
            <span>{selectedPost.created_at}</span>
          </div>
          <div className="post-description">
            {selectedPost[`body_${userLanguage}`]}
          </div>
        </>
      )}
    </div>
  </div>
)}
      <Footer></Footer>
    </div>
  );
};

export default BlogPage;
