import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import EmployeeCard from "../components/EmployeeCard"; //  تأكد  من  تحديد المسار الصحيح  لمكون EmployeeCard
import { Helmet } from "react-helmet";
import Socialmediaicons from "../components/socialmediaicons";
import FixedTopPageBtn from "../components/FixedTopPageBtn";

const FoundersPage = () => {
  const employees = [
    {
      image: "./assets/image/team/mahmoud.jpg",
      name: "Mahmoud Shihab",
      position: "CEO",
      skills: [
        "CEO in Invest company",
        "CEO in LION company",
        "Manger in Encryption Company",
 
      ],
    },
    {
      image: "./assets/image/Mazen Otabashi.jpg",
      name: "Mazen Otabashi",
      position: "International Consultant",
      skills: [
        "Director of Mazennio B.V social media in Rotterdam",
        "Programmer",
        "Regional Director of Pronto production in Bulgari",
        "the owner of Veozen consultatie in the Netherlands",
      
      ],
    },
    {
      image: "./assets/image/team/ceo.jpg",
      name: "Mohammed Shra",
      position: "Manager",
      skills: [
        "Web developer",
        "Programmer",
        "Graphic Designer",
        "Full stack Developer",
        "Database management",
      ],
    },
    {
      image: "./assets/image/team/nader.jpg",
      name: "Nader Yousef",
      position: "Manager Assistant",
      skills: [
        "Manger Assistant in Invest company",
        "Manger Assistant in LION company",
        "Manger Assistant in Encryption Company",

      ],
    },
  ];
  return (
    <div>
      <Helmet>
         <link rel="stylesheet" href="./css/ourteam.css "/>
         <title>MMN4 Founders</title>
         <meta
      name="description"
      content="MMN Commercial Advertising is your trusted partner for digital branding. We specialize in promoting your brand across various digital platforms, including social media and search engines. Our team of experienced professionals in website design, visual identities, and public content ensures your brand stands out in the digital world. We're committed to supporting your business activities and enhancing your online presence. Let us help you reach your target audience effectively."
    />
      </Helmet>
      <Socialmediaicons/>
      <FixedTopPageBtn/>
      <Header></Header>
      <div className="ourteam-sec">
        <div className="card-team-container">
          {employees.map((employee, index) => (
            <EmployeeCard
              key={index}
              image={employee.image}
              name={employee.name}
              position={employee.position}
              skills={employee.skills}
            />
          ))}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default FoundersPage;
