import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../containers/Language";
import { Text } from "../containers/Language";

const ReadMore = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shortenedText = text.slice(0,  120); //  يمكنك  تعديل  هذا الرقم  لتحديد  عدد الأحرف التي  تريد  عرضها
  const { userLanguage } = useContext(LanguageContext);
  const languageDirection = userLanguage === "ar" ? "arabicStyle" : "englishStyle";
  const languageButton = userLanguage === "ar" ? "arabicb" : "englishb";
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="readmorecontainer">
      <p  className={`  ${languageDirection}`}>{isExpanded ? text : shortenedText}</p>
      {text.length > shortenedText.length && (
        <button className={`${languageButton}`} onClick={toggleExpansion}>
          {isExpanded ? <Text tid="Read less" /> : <Text tid="Read more" />}
        </button>
      )}
    </div>
  );
};
const Services = () => {
  const { userLanguage } = useContext(LanguageContext);
  const languageDirection = userLanguage === "ar" ? "arabicStyle" : "englishStyle";
  const languageD = userLanguage === "ar" ? "arabicStyleflex" : "englishStyleflex";
  const [services, setServices] = useState([]);
  let dirSerClass = "";
  switch (userLanguage) {
    case "ar":
      dirSerClass = "dirSer-ar";
      break;
    case "en":
      dirSerClass = "dirSer-en";
      break;
    // Add more cases for other languages if needed
    default:
      dirSerClass = "dirSer-default";
  }
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch('https://api.mmnq8.com/api/services');
        const data = await response.json();
        setServices(data.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <div className="services">
    <div className="container">
      <div className="header-serv"><Text tid="Services" /></div>
      <div className="content-serv ">
        {services.map((service) => (
          <div className={`item-serv ${languageD}`} key={service.id}>
            <img src={service[`imageUrl_${userLanguage}`]} alt={service[`name_${userLanguage}`]} className="image-container" />
            <div className="serv-sec">
              <h3 className={` ${languageDirection}`}>{service[`name_${userLanguage}`]}</h3>
              <ReadMore text={service[`description_${userLanguage}`]} />
            </div>
          </div>
        ))}
      </div>
      <a href="/services" className="btn-serv btn-primary">
        <Text tid="View All" />
      </a>
    </div>
  </div>
  );
};

export default Services;