import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PostDetailsPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const response = await fetch(`https://api.mmnq8.com/api/posts/${id}`);
        const data = await response.json();
        if (data.success) {
          setPost(data.data);
        } else {
          console.error('Failed to fetch post details', data.message);
        }
      } catch (error) {
        console.error('Error fetching post details:', error);
      }
    };

    fetchPostDetails();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Render the post details here */}
      <h1>{post.title}</h1>
      <p>{post.body}</p>
      {/* Add other post details as needed */}
    </div>
  );
};

export default PostDetailsPage;