import React from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import Portfolio from "../components/Portfolio"
import Socialmediaicons from '../components/socialmediaicons';
import FixedTopPageBtn from '../components/FixedTopPageBtn';
import { Helmet } from 'react-helmet';
const PortfolioPage = () => {
    return (
        <div>
          <Helmet>
          <title>MMN4 Portfolio</title>
          </Helmet>
             <Socialmediaicons/>
             <FixedTopPageBtn/>
             <Header></Header>
               <Portfolio/>    
             <Footer></Footer>
        </div>
    );
}

export default PortfolioPage;
