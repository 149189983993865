import React, { useContext, useState, useEffect } from "react";
import { CSSTransition } from 'react-transition-group';
import { Text } from "../containers/Language";
import LanguageSelector from "./LanguageSelector";
import { LanguageContext } from "../containers/Language";
const AboutUs = () => {
  const [showServices, setShowServices] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowServices(true);
        }
      },
      { threshold:  0.1 }
    );

    const target = document.querySelector('.ourservices');
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);
  const { userLanguage } = useContext(LanguageContext);
  const languageDirection = userLanguage === "ar" ? "arabstyle" : "engstyle"; 
  let dirClass = "";
  switch (userLanguage) {
    case "ar":
      dirClass = "dir-ar";
      break;
    case "en":
      dirClass = "dir-en";
      break;
    // Add more cases for other languages if needed
    default:
      dirClass = "dir-default";
  }
  return (
    <div  className={`about-sec ${languageDirection}`}>
      <div className="about-us"><Text tid="About Us" /></div>
      <div className="content-about">
        <div className="about-main-top">
          <h3 className={dirClass}> <Text tid="Who Are We" /></h3>
          <p className={dirClass}>
            <Text tid="Who Are We description" />
          </p>
        </div>
        <CSSTransition in={showServices} timeout={500} classNames="fade">
      <div className="ourservices">
        <h3 className={dirClass}> <Text tid="ourservices" /></h3>
        <p className={dirClass}>
          <Text tid="ourservices description" />
        </p>
      </div>
    </CSSTransition>
        <div className="ourvismis">
          <div className="vis">
            <h3 className={dirClass}> <Text tid="Our Vision" /></h3>
            <p className={dirClass}>
              <Text tid="Our Vision desc" />
            </p>
          </div>
          <div className="mis">
            <h3 className={dirClass}><Text tid="Our Mission" /></h3>
            <p className={dirClass}>
              <Text tid="Our Mission desc" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
