import React, { useContext, useState, useEffect, useRef } from "react";
import { Text } from "../containers/Language";
import LanguageSelector from "./LanguageSelector";
import { LanguageContext } from "../containers/Language";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Header = () => {
  const { userLanguage } = useContext(LanguageContext);
  const languageDirection = userLanguage === "ar" ? "ara" : "eng"; 
  const languageDirectionn = userLanguage === "ar" ? "aram" : "engm"; 

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [services, setServices] = useState([]);
  const burgerIconRef = useRef(null);
  const burgerListRef = useRef(null);
  const overlayBurgerRef = useRef(null);
  const searchDropdownRef = useRef(null);
  const searchSelectRef = useRef(null);

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [burgerListDisplay, setBurgerListDisplay] = useState("none");

  let headerClass = "";
  switch (userLanguage) {
    case "ar":
      headerClass = "header-ar";
      break;
    case "en":
      headerClass = "header-en";
      break;
    // Add more cases for other languages if needed
    default:
      headerClass = "header-default";
  }

  useEffect(() => {
    fetch("https://api.mmnq8.com/api/services")
      .then((response) => response.json())
      .then((data) => {
        setServices(data.data);
      })
      .catch((error) => console.error("Error fetching data:", error));

    const burgerIcon = burgerIconRef.current;

    if (burgerIcon) {
      const toggleBurgerMenu = () => {
        setBurgerListDisplay((prevDisplay) =>
          prevDisplay === "none" ? "block" : "none"
        );
      };

      burgerIcon.addEventListener("click", toggleBurgerMenu);

      return () => {
        burgerIcon.removeEventListener("click", toggleBurgerMenu);
      };
    }
  }, []);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
    {/* <Helmet>
        <link rel="stylesheet" href="./css/header.css"/> 
    </Helmet> */}
      <header className={headerClass}>
        <div className="nav">
          <div className="container">
            <div className="nav-group">
              <div className="email">
                <i className="fa fa-envelope" />
                <a href="mailto:info@mmn4.com">info@mmn4.com</a>
              </div>
              <div className="phone-num">
                <i className="fa fa-phone" />
                <a href="tel:00971582730255">00971582730255</a>
              </div>
            </div>
            <div className="nav-group">
              <div className="location">
                <img
                  className="vector-icon11"
                  alt="location"
                  src="../.././assets/index/vector11.svg"
                />
                <span>Kuwait</span>
              </div>
              <div className="social-media-list">

                <a href="https://x.com/MMN4Global?s=09">
                   <i className="fa fa-x"/>
                </a>
                <a href="https://www.instagram.com/mmn4.global?igsh=cDh3dXl3ZGxqcDl1">
                <i className="fab fa-instagram"/>
                </a>
                <a href="https://www.facebook.com/profile.php?id=61556072264467">
                <i className="fab fa-facebook"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header">
          <div className="container">
            <img
              className="logo"
              src="../.././assets/icons/logo.svg"
              alt="logo"
            />
            <ul className="header-list">
              <li>
                {" "}
                <a href="/" className="">
                  <Text tid="Home" />
                </a>
              </li>
              <li>
                {" "}
                <a href="/services">
                  <Text tid="Services" />
                </a>
              </li>
              <li className="item-hidden">
                {" "}
                <a href="/about">
                  <Text tid="About Us" />
                </a>
              </li>
              <li className="item-hidden">
                {" "}
                <a href="/portfolio">
                  <Text tid="Portfolio" />
                </a>
              </li>
              <li className="item-hidden">
                {" "}
                <a href="/contact">
                  <Text tid="Contact Us" />
                </a>
              </li>
              <li>
                {" "}
                <a href="/founders">
                  <Text tid="Founders" />
                </a>
              </li>
              <li>
                {" "}
                <a href="/blog" className="item-hidden">
                  <Text tid="Blog" />
                </a>
              </li>
              <li>
                <LanguageSelector />
              </li>
            </ul>
            <div className="search-container">
              <img
                className="vector-icon8"
                alt="search"
                src="../.././assets/index/vector8.svg"
              />
              <input type="text" id="search-input" placeholder= "search" />
              <i
                className="fas fa-angle-down"
                id="search-select"
                onClick={handleDropdownClick}
              />
              <div
                className="search-dropdown"
                style={{ height: isDropdownOpen ? "auto" : "0px" }}
              >
                <hr />
                <ul id="services-list">
  {services.map((service) => (
    <li className={` ${languageDirection}`} key={service.id}>
      <Link style={{ color:"black" }} to={`/services`}>{service[`name_${userLanguage}`]}</Link>
    </li>
   ))}
</ul>
              </div>
            </div>
            <img
              ref={burgerIconRef}
              className="burger-icon"
              src="../.././assets/icons/hum.svg"
              alt="burger icon"
              onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
            />
          </div>
        </div>
      </header>

      <div
        className={`burger-list ${isBurgerMenuOpen ? "open" : ""}`}
        style={{ display: burgerListDisplay }}
      >
        <div className="container">
          <img
            className="logo-list"
            src=".././assets/icons/logo.svg"
            alt="logo list"
          />
          <ul className="header-list-mobile">
            <li className="item-list-mob">
              {" "}
              <a href="/" className={` ${languageDirectionn}`}>
                <Text tid="Home" />
              </a>
            </li>
            <li className="item-list-mob">
              {" "}
              <a href="/services" className={` ${languageDirectionn}`}>
                <Text tid="Services" />
              </a>
            </li>
            <li className="item-list-mob">
              {" "}
              <a href="/about" className={` ${languageDirectionn}`}>
                <Text tid="About Us" />
              </a>
            </li>
            <li className="item-list-mob">
              {" "}
              <a href="/portfolio" className={` ${languageDirectionn}`}>
                <Text tid="Portfolio" />
              </a>
            </li>
            <li className="item-list-mob">
              {" "}
              <a href="/contact" className={` ${languageDirectionn}`}>
                <Text tid="Contact Us" />
              </a>
            </li>
            <li className="item-list-mob">
              {" "}
              <a href="/founders" className={` ${languageDirectionn}`}>
                <Text tid="Founders" />
              </a>
            </li>
            <li className="item-list-mob">
              {" "}
              <a href="/blog" className={` ${languageDirectionn}`}>
                <Text tid="Blog" />
              </a>
            </li>
          </ul>
          <div className="grow">
            <div className="grow-tit">
              <Text tid="Grow" />
            </div>
            <div className="grow-desc">
              <Text tid="Your Business with Us" />
            </div>
          </div>
          <div className="social-media-list">

          <a href="https://x.com/MMN4Global?s=09">
                   <i className="fa fa-x"/>
                </a>
                <a href="https://www.instagram.com/mmn4.global?igsh=cDh3dXl3ZGxqcDl1">
                <i className="fab fa-instagram"/>
                </a>
                <a href="https://www.facebook.com/profile.php?id=61556072264467">
                <i className="fab fa-facebook"/>
                </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
