import React from "react";

const FixedTopPageBtn = () => {
  return (
    <div>
      <div className="fixed-circle">
        <a href="#top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    </div>
  );
};

export default FixedTopPageBtn;
